















































































import { defineComponent, onMounted, reactive } from "@vue/composition-api"
import { pages } from "@microsoft/teams-js"
import { listStructureContainer } from "@/containers/ListStructureContainer"
import Loading from "@/components/Loading.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { notificationContainer } from "@/containers/NotificationContainer"
import { Project } from "@/models/Project"
import { MeetingStructure } from "@/models/Meeting"
import { List } from "node_modules/@microsoft/microsoft-graph-types/microsoft-graph"
import { memoContainer } from "@/containers/MemoContainer"
import { metricContainer } from "@/containers/MetricContainer"
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import IconButton from "@/components/buttons/IconButton.vue"

interface State {
  loadingProjects: boolean
  listIsCreating: boolean
  projectIsCreating: boolean
  onCreating: boolean
}

export default defineComponent({
  components: {
    Loading,
    DefaultButton,
    IconButton,
  },
  setup() {
    const state = reactive<State>({
      loadingProjects: true,
      listIsCreating: true,
      projectIsCreating: true,
      onCreating: false,
    })
    const inputState = reactive<{
      site: MicrosoftGraph.Site | null
      projects: Array<{
        project: Project
        meetings: MeetingStructure[]
      }>
      entityId: string
    }>({
      site: null,
      projects: [],
      entityId: "",
    })

    const {
      createNewListStructureAsync,
      syncStructure,
    } = listStructureContainer.useContainer()
    const {
      createNotificationBaseFolderIfNotExist,
    } = notificationContainer.useContainer()
    const {
      getProjectOrNullAsync,
      getChannelProjects,
      createProjectAsync,
      updateProjectAsync,
      getProjectSiteAsync,
    } = projectsContainer.useContainer()
    const { CreateInstallMetric } = metricContainer.useContainer()

    const { getContext } = teamsContextContainer.useContainer()
    const { createBaseFolderIfNotExist } = memoContainer.useContainer()
    const createNewProjects = async () => {
      const context = await getContext()
      state.onCreating = true
      if (!inputState.site?.id) {
        throw new Error("SharepointのSite状態が取得できません")
      }
      let listValue: List | null = null
      if (!inputState.entityId) {
        listValue = await createNewListStructureAsync(inputState.site.id)
        if (listValue.id) inputState.entityId = listValue.id
      } else {
        listValue = await syncStructure(inputState.site.id, inputState.entityId)
      }
      state.listIsCreating = false
      if (!inputState.entityId) {
        pages.config.setValidityState(false)
        return
      }
      // プロジェクト作成
      const ret = await getProjectOrNullAsync(inputState.entityId)
      let currentProject: Project | null = ret ? new Project(ret) : null
      if (!currentProject) {
        CreateInstallMetric(context.tid, context.userObjectId)
        currentProject = await createProjectAsync(
          new Project({
            groupId: context.groupId,
            siteId: inputState.site.id,
            sharepointListWeburl: listValue?.webUrl,
            sharepointListId: inputState.entityId,
            appId: context.appId,
            teamId: context.teamId,
            channelId: context.channelId,
            isPrivate: context.isPrivate,
          } as Project)
        )
      } else if (
        currentProject.groupId !== context.groupId ||
        currentProject.siteId !== inputState.site.id ||
        currentProject.sharepointListId !== inputState.entityId ||
        currentProject.sharepointListWeburl !== listValue?.webUrl ||
        currentProject.appId !== context.appId ||
        currentProject.teamId !== context.teamId ||
        currentProject.channelId !== context.channelId ||
        currentProject.isPrivate !== context.isPrivate
      ) {
        const updatedProject = new Project(
          Object.assign(currentProject, {
            groupId: context.groupId,
            siteId: inputState.site.id,
            sharepointListId: inputState.entityId,
            sharepointListWeburl: listValue?.webUrl,
            appId: context.appId,
            teamId: context.teamId,
            channelId: context.channelId,
            isPrivate: context.isPrivate,
          })
        )
        currentProject = await updateProjectAsync(
          currentProject.id,
          updatedProject
        )
      }
      // フォルダ作成
      await Promise.all([
        createNotificationBaseFolderIfNotExist(inputState.site.id),
        createBaseFolderIfNotExist(inputState.site.id),
      ])
      state.projectIsCreating = false
      if (!currentProject) {
        pages.config.setValidityState(false)
        return
      }
      pages.config.setConfig({
        contentUrl: window.location.origin,
        entityId: inputState.entityId,
        removeUrl: `${window.location.origin}/remove-config`,
        suggestedDisplayName: "定例会議アシスト",
      })
      pages.config.setValidityState(true)
    }

    onMounted(async () => {
      const context = await getContext()
      if (!context.isPrivate && !context.groupId) {
        throw Error("Sharepointの状態が取得できません")
      }
      // リストフォルダ作成
      inputState.site = await getProjectSiteAsync(
        context.teamSiteDomain,
        context.teamSitePath
      )
      if (!inputState.site?.id) {
        throw new Error("SharepointのSite状態が取得できません")
      }
      const projects = await getChannelProjects(context.channelId)
      inputState.projects = projects
      state.loadingProjects = false
      if (projects.length === 0) {
        await createNewProjects()
        return
      }
    })

    return {
      state,
      inputState,
      createNewProjects,
    }
  },
})
